<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-layout wrap>
              <v-flex
                align-self-center
                text-left
              >
                <span style="font-family: poppinssemibold">Projects</span>
              </v-flex>

              <v-flex
                xl3
                lg3
                md3
                sm3
                xs12
                pb-2
                pl-6
                text-right
                grow
                v-if="userType == 'SuperAdmin'"
              >
                <v-dialog
                  :retain-focus="false"
                  v-model="dialog"
                  persistent
                  max-width="50%"
                  :key="dialog"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="font-family: poppinsmedium"
                      block
                      small
                      dark
                      class="rounded-xl text-none"
                      color="#5F4B8BFF"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon small>mdi-pencil-plus-outline</v-icon>&nbsp; Add
                      Project
                    </v-btn>
                  </template>
                  <v-card>
                    <v-form v-model="addcat" ref="addcat">
                      <!-- <v-card-title>
                       
                      </v-card-title> -->
                      <!-- <v-card-text> -->
                      <v-layout wrap>
                        <v-flex xs12 style="background-color: #5f4b8bff">
                          <span
                            style="
                              color: #ffffff;
                              font-size: 20px;
                              font-family: poppinsbold;
                              padding: 2px;
                            "
                            class="RB"
                            >ADD PROJECT</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout wrap pa-12 justify-center fill-height>
                        <v-flex xs12 align-self-center text-left>
                          <span>Project Name*</span>
                          <br />
                          <v-text-field
                            v-model="name"
                            placeholder="Project Name"
                            :rules="[rules.required]"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 align-self-center text-left py-3>
                          <span>Description</span>
                          <br />
                          <v-text-field
                            v-model="projectDescription"
                            placeholder="Project Description"
                            :rules="[rules.required]"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12 align-self-center text-left>
                          <span>Location</span>
                          <br />
                          <v-text-field
                            v-model="projectLocation"
                            placeholder="Project Location"
                            :rules="[rules.required]"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12 align-self-center text-left pt-3>
                          <span>Project Head*</span>
                          <br />
                          <v-autocomplete
                            v-model="employee"
                            :items="employeeList"
                            placeholder="Project Head"
                            :rules="[rules.required]"
                            item-text="name"
                            item-value="_id"
                            required
                            outlined
                            hide-details
                            dense
                          ></v-autocomplete>
                        </v-flex>
                      </v-layout>
                      <!-- </v-card-text> -->
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="#ED2B33FF" text @click="dialogclose()">
                          Close
                        </v-btn>
                        <v-btn
                          color="#0063B2FF"
                          text
                          :disabled="!addcat"
                          @click="addOrganisation()"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-progress-linear
              :color="appColor"
              rounded
              value="100"
              height="2px"
            ></v-progress-linear>
          </v-flex>
          <template v-if="projects.length > 0">
            <v-flex xs12 px-4 v-for="(item, i) in projects" :key="i" pb-3>
              <v-card class="small" pa-3 style="font-family: poppinsmedium">
                <v-layout wrap justify-start text-left>
                  <v-flex xs12>
                    <v-card color="gray" ripple="true">
                      <v-layout wrap pa-3>
                        <v-flex xs12 md6 py-3>
                          <router-link
                            :to="
                              '/projectView?id=' +
                              item._id +
                              '&name=' +
                              item.projectName
                            "
                          >
                            <v-layout wrap pr-1>
                              <v-flex xs12 pb-2 class="xlarge">
                                <span style="font-family: poppinsbold">{{
                                  item.projectName
                                }}</span>
                              </v-flex>
                              <v-flex xs12>
                                <v-layout wrap>
                                  <v-flex xs4>
                                    <span> <b> Project Head </b></span>
                                  </v-flex>
                                  <v-flex xs8>
                                    <span>{{ item.projectHead.name }}</span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap py-1>
                                  <v-flex xs4>
                                    <span><b>Location </b></span>
                                  </v-flex>
                                  <v-flex xs8>
                                    <span>{{ item.location }}</span>
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap pb-1>
                                  <v-flex xs4>
                                    <span> <b> Donors </b></span>
                                  </v-flex>
                                  <v-flex xs8>
                                    <span
                                      v-for="(itemz, m) in item.orgNames"
                                      :key="m"
                                      >{{ itemz }},</span
                                    >
                                    &nbsp;
                                  </v-flex>
                                </v-layout>
                                <v-layout wrap pb-1>
                                  <v-flex xs4>
                                    <span> <b>WTI team </b></span>
                                  </v-flex>
                                  <v-flex xs8>
                                    <span
                                      v-for="(itemz, m) in item.EmployeeNames"
                                      :key="m"
                                      >{{ itemz }}, &nbsp;</span
                                    >
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </router-link>
                        </v-flex>

                        <v-divider vertical> </v-divider>

                        <v-flex xs12 md6 pl-3 py-3>
                          <router-link
                            :to="
                              '/projectView?id=' +
                              item._id +
                              '&name=' +
                              item.projectName
                            "
                          >
                            <v-layout wrap>
                              <v-flex xs12 md4>
                                <span> <b>Project Total Split </b></span>
                              </v-flex>
                              <v-flex xs12 md4>
                                <template v-if="item.targetdetails.length > 0">
                                  <span
                                    v-for="(item2, j) in item.targetdetails"
                                    :key="j"
                                  >
                                    {{ item2.fyear.fyear }} ->
                                    {{ item2.totalTarget }} (INR) <br
                                  /></span>
                                </template>
                                <template v-else>
                                  <span style="color: red">
                                    No Split Assigned...!
                                  </span>
                                </template>
                              </v-flex>
                            </v-layout>

                            <v-layout wrap pt-2>
                              <v-flex xs12 md4>
                                <span> <b>Description </b></span>
                              </v-flex>
                              <v-flex xs12 md8>
                                {{ item.description }}
                              </v-flex>
                            </v-layout>
                            <!-- <v-layout wrap pt-2>
                              <v-flex xs12 md4>
                                <span> <b>Pie Chart </b></span>
                              </v-flex>
                              <v-flex xs12 md8>
                                <v-flex xs12 pt-2>
                                  <apexchart
                                    type="pie"
                                    width="380"
                                    :options="chartOptions2"
                                    :series="series2"
                                  >
                                  </apexchart>
                                </v-flex>
                              </v-flex>
                            </v-layout> -->
                          </router-link>
                        </v-flex>

                        <v-flex xs12>
                          <v-divider> </v-divider>
                        </v-flex>

                        <!-- <v-flex xs12>
                          <v-expansion-panels flat focusable>
                            <v-expansion-panel>
                              <v-expansion-panel-header>
                                Project Splits
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <ProjectTarget :TSplit="item.targetdetails" />
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-flex> -->
                        <v-flex xs12 text-right>
                          <v-layout wrap justify-end text-right>
                            <v-flex
                              xs12
                              sm4
                              lg1
                              pa-2
                              v-if="userType == 'SuperAdmin'"
                            >
                              <v-btn
                                color="#0063B2FF"
                                style="font-family: poppinsmedium"
                                block
                                outlined
                                dark
                                x-small
                                @click="editcat(item)"
                                >Edit</v-btn
                              >
                            </v-flex>
                            <v-flex
                              xs12
                              sm4
                              lg1
                              pa-2
                              v-if="userType == 'SuperAdmin'"
                            >
                              <v-dialog
                                persistent
                                v-model="item.delete"
                                max-width="600px"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    x-small
                                    style="font-family: poppinsmedium"
                                    class="mr-2"
                                    dark
                                    block
                                    outlined
                                    v-on="on"
                                    v-bind="attrs"
                                    color="#ED2B33FF"
                                  >
                                    Delete
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-card-title
                                    >Are you sure you want to delete this
                                    Project?</v-card-title
                                  >
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      color="#ED2B33FF"
                                      text
                                      @click="item.delete = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      color="#0063B2FF"
                                      text
                                      @click="deleteItem(item)"
                                      >OK</v-btn
                                    >
                                    <v-spacer></v-spacer>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template>
          <!-- <template v-if="projects.length > 0">
            <v-flex
              xs12
              md6
              sm6
              lg6
              xl6
              align-self-center
              v-for="(item, i) in projects"
              :key="i"
              fill-height
              pa-4
            >
              <v-card
                tile
                elevation="2"
                outlined
                style="
                  border-radius: 0px 0px 20px !important;
                  border-bottom: 5px solid #5f4b8bff;
                "
              >
                <v-layout
                  text-left
                  wrap
                  justify-center
                  py-4
                  style="font-family: poppinsmedium"
                >
                  <v-flex xs10 align-self-center text-left pa-3>
                    <span style="font-family: poppinsbold"
                      >{{ item.projectName }}
                    </span>
                  </v-flex>
                  <v-flex xs2 text-right pr-2>
                    <v-btn
                      color="green"
                      small
                      text
                      :to="
                        '/projectView?id=' +
                        item._id +
                        '&name=' +
                        item.projectName
                      "
                      >view</v-btn
                    >
                  </v-flex>

                  <v-flex xs3 align-self-start text-left px-3>
                    <span> Description </span>
                  </v-flex>

                  <v-flex xs9>
                    <span>{{ item.description }}</span>
                  </v-flex>

                  <v-flex xs3 align-self-start text-left px-3>
                    <span>Location</span> &nbsp;
                  </v-flex>
                  <v-flex xs9>
                    <span style="font-family: poppinsmedium">{{
                      item.location
                    }}</span>
                  </v-flex>
                  <template v-if="item.projectHead">
                    <v-flex xs3 align-self-start text-left px-3>
                      <span>Head</span> &nbsp;
                    </v-flex>
                    <v-flex xs9>
                      <span style="font-family: poppinsmedium">{{
                        item.projectHead.name
                      }}</span>
                    </v-flex>
                  </template>
                  <template v-if="item.fromTime && item.toTime">
                    <v-flex
                      xs3
                      align-self-start
                      text-left
                      px-3
                      v-if="item.fromTime"
                    >
                      <span>DeadLine</span> &nbsp;
                    </v-flex>
                    <v-flex xs9>
                      <span style="font-family: poppinsmedium">
                        From : {{ item.fromTime.slice(0, 10) }} to :
                        {{ item.toTime.slice(0, 10) }}
                      </span>
                    </v-flex>
                  </template>

                  <v-flex xs12 px-3 pt-2 v-if="userType == 'SuperAdmin'">
                    <v-divider></v-divider>
                  </v-flex>

                  <v-flex xs12 sm4 pa-2 v-if="userType == 'SuperAdmin'">
                    <v-btn
                      color="#0063B2FF"
                      style="font-family: poppinsmedium"
                      block
                      text
                      dark
                      @click="editcat(item)"
                      >Edit</v-btn
                    >
                  </v-flex>
                  <v-flex xs12 sm4 pa-2 v-if="userType == 'SuperAdmin'">
                    <v-dialog
                      persistent
                      v-model="item.delete"
                      max-width="600px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          style="font-family: poppinsmedium"
                          class="mr-2"
                          dark
                          block
                          text
                          v-on="on"
                          v-bind="attrs"
                          color="#ED2B33FF"
                        >
                          Delete
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Project?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="#ED2B33FF"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="#0063B2FF"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </template> -->

          <v-flex xs12 v-else>
            <span style="font-family: poppinsbold" class="RB"
              >Empty List....!</span
            >
          </v-flex>

          <v-dialog persistent v-model="editdialog" max-width="50%">
            <v-card tile>
              <!-- <v-card-title>
                <span class="headline">Edit Organisation</span>
              </v-card-title> -->
              <!-- <v-card-text> -->
              <v-layout wrap>
                <v-flex xs12 style="background-color: #5f4b8bff">
                  <span
                    style="
                      color: #ffffff;
                      font-size: 20px;
                      font-family: poppinsbold;
                      padding: 2px;
                    "
                    class="RB"
                    >EDIT ORGANISATION</span
                  >
                </v-flex>
              </v-layout>
              <v-layout wrap pa-12>
                <!-- {{editingitem}}.... -->
                <v-flex xs12 text-left>
                  Organisation Name*
                  <br />
                  <v-text-field
                    v-model="editingitem.projectName"
                    placeholder="Project Name"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                    hide-details
                  >
                  </v-text-field>
                </v-flex>
                <v-flex xs12 align-self-center text-left py-3>
                  <span>Description</span>
                  <br />
                  <v-text-field
                    v-model="editingitem.description"
                    placeholder="Project Description"
                    :rules="[rules.required]"
                    required
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 align-self-center text-left>
                  <span>Location</span>
                  <br />
                  <v-text-field
                    v-model="editingitem.location"
                    placeholder="Project Location"
                    :rules="[rules.required]"
                    required
                    outlined
                    hide-details
                    dense
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 align-self-center text-left pt-3>
                  <span>Project Head*</span>
                  <br />
                  <v-autocomplete
                    v-if="editingitem.projectHead"
                    v-model="editingitem.projectHead"
                    :items="employeeList"
                    placeholder="Project Head"
                    :rules="[rules.required]"
                    item-text="name"
                    item-value="_id"
                    required
                    outlined
                    hide-details
                    dense
                  ></v-autocomplete>
                </v-flex>
                <!-- <v-flex xs12 md6 pr-md-1 align-self-center text-left pt-3>
                  <span>Starting Date</span>
                  <br />
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="editingitem.startingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        outlined
                        v-model="editingitem.startingDate"
                        :rules="[rules.required]"
                        label="Select Date"
                        append-icon="mdi-calendar"
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editingitem.startingDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(editingitem.startingDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 md6 pl-md-1 align-self-center text-left pt-3>
                  <span>Ending Date</span>
                  <br />
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    :return-value.sync="editingitem.endingDate"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :rules="[rules.required]"
                        v-model="editingitem.endingDate"
                        label="Select Date"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editingitem.endingDate"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu1 = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu1.save(editingitem.endingDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-flex> -->
              </v-layout>
              <!-- </v-card-text> -->
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="#ED2B33FF" text @click="getdData"> Close </v-btn>
                <v-btn
                  v-if="editingitem.projectName"
                  color="#0063B2FF"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import ImageComp from  "@/components/Common/singleImage";
// import ProjectTarget from "./projectTargets.vue";
import axios from "axios";
// import VueApexCharts from "vue-apexcharts";
export default {
  data() {
    return {
      organisations: [],
      ORG: null,
      balanceAmount: null,
      employee: null,
      menu: false,
      menu1: false,
      startingDate: null,
      endingDate: null,
      employeeList: [],
      projectDescription: null,
      projectLocation: null,
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      editdialog: false,
      editingitem: [],
      name: "",
      pageCount: 1,
      piedata: [],
      appLoading: false,
      editedIndex: -1,
      series2: [],
      currentPage: 1,
      g: false,
      perPage: 4,
      totalData: 0,
      piechartdata: [],
      totalRows: 0,
      dialogDelete: false,
      search: "",
      itemname: "",
      addcat: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      projects: [],
      data: [],
      coverImageFile: null,
      selectedFiles: [],
      image: null,
      chartOptions2: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          "Committed",
          "Committed Not Confirmed",
          "Recived",
          "Total Amount",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      coverImageArray: [],
      formDataCover: new FormData(),
      flagg: true,
    };
  },
  components: {
    // ProjectTarget,
    // apexchart: VueApexCharts,
  },

  mounted() {
    this.getData();
    this.employeeLists();
    // this.getOrg();
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    userType() {
      return this.$store.state.userType;
    },
  },

  methods: {
    getOrg() {
      this.appLoading = true;
      axios({
        url: "/organization/type/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.organisations = response.data.data;
          // this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    employeeLists() {
      this.appLoading = true;
      axios({
        url: "/superadmin/view/employeelist/fundRise",
        method: "post",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.employeeList = response.data.data;
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getdData() {
      this.editdialog = false;
      this.getData();
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/projects/remove/fundraise",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getData() {
      this.appLoading = true;
      (this.series2 = []);
      axios({
        url: "/projects/getalllist/fundraise",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.series2 = [];
          this.projects = response.data.data;
          this.totalData = response.data.count;
          this.piedata = response.data.data;
          for (var i = 0; i < this.piedata.length; i++) {
            this.series2 = [
              this.piedata[i].chartData.confirmed,
              this.piedata[i].chartData.notconfirmed,
              this.piedata[i].chartData.recived,
              this.piedata[i].chartData.toberaised,
              this.piedata[i].chartData.totalAmount
            ];
          }
          this.appLoading = false;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.dialog = false;
    },

    addOrganisation() {
      var data = {};
      data["projectName"] = this.name;
      data["description"] = this.projectDescription;
      data["location"] = this.projectLocation;
      data["projectHead"] = this.employee;
      data["fromTime"] = this.startingDate;
      data["toTime"] = this.endingDate;

      axios({
        // url: "/add/project",
        url: "projects/add/fundraise",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editcat(item) {
      this.editingitem = item;
      this.editingitem.projectHead = item.projectHead._id;
      if (item.fromTime) {
        this.editingitem.startingDate = item.fromTime.slice(0, 10);
      }

      if (item.toTime) {
        this.editingitem.endingDate = item.toTime.slice(0, 10);
      }

      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.editingitem._id;
      data["projectName"] = this.editingitem.projectName;
      data["description"] = this.editingitem.description;
      data["location"] = this.editingitem.location;
      data["projectHead"] = this.editingitem.projectHead;
      data["fromTime"] = this.editingitem.startingDate;
      data["toTime"] = this.editingitem.endingDate;

      axios({
        url: "/projects/edit/fundraise",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.editdialog = false;

          this.appLoading = false;
          if (response.data.status) {
            // if (this.coverImageFile) {
            //   this.uploadCoverImages(this.editingitem._id);
            // }
            this.msg = "Edited Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
